import { StyleSizeType } from '@/ui-kit/styles/enums';
import { CAROUSEL_SPACE_MAP } from '@/ui-kit/components/carousel/constants';

export const HOME_CAROUSEL_BREAKPOINTS = {
  [StyleSizeType.XSmall]: {
    slidesPerView: 1,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Small],
  },
  [StyleSizeType.Small]: {
    slidesPerView: 2,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Small],
  },
  [StyleSizeType.Medium]: {
    slidesPerView: 3,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Large],
  },
  [StyleSizeType.Large]: {
    slidesPerView: 3,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.XLarge],
  },
  [StyleSizeType.XLarge]: {
    slidesPerView: 3,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.XXLarge],
  },
};

export const HOME_CAROUSEL_HEIGHT = 350;

export default {
  HOME_CAROUSEL_BREAKPOINTS,
  HOME_CAROUSEL_HEIGHT,
};
