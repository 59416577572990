
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import Carousel from '@/ui-kit/components/carousel';
import { CarouselPaginationType } from '@/ui-kit/components/carousel/enums';
import HomeCarouselItem from './carousel-item';
import { HOME_CAROUSEL_BREAKPOINTS, HOME_CAROUSEL_HEIGHT } from './constants';
import HomeStoreName from '@/store/home/name';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'HomeCarousel',
  components: { Carousel, HomeCarouselItem },
  data() {
    return {
      activeIndex: 0,
      HOME_CAROUSEL_BREAKPOINTS,
      HOME_CAROUSEL_HEIGHT,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      HomePageEvent,
      CarouselPaginationType,
    };
  },
  computed: {
    customerFeedbackContent() {
      return this.$store.state[HomeStoreName].customerFeedbackContent;
    },
  },
  methods: {
    handleScroll(index) {
      this.activeIndex = index;
    },
    getIsActive(index) {
      return index === this.activeIndex;
    },
  },
};
