
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import Gutter from '@/ui-kit/components/gutter';
import Icon from '@/ui-kit/components/icon';
import { IconType } from '@/ui-kit/components/icon/enums';

export default {
  name: 'HomeSecurityTitleDescription',
  components: {
    Icon,
    Gutter,
  },
  data() {
    return {
      IconType,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
    };
  },
};
