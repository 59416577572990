
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import CarouselItem from '@/ui-kit/components/carousel-item';
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import Paper from '@/ui-kit/components/paper';
import Gutter from '@/ui-kit/components/gutter';
import Icon from '@/ui-kit/components/icon';
import { IconType } from '@/ui-kit/components/icon/enums';

export default {
  name: 'HomeCarouselItem',
  components: {
    Icon,
    Gutter,
    Paper,
    CarouselItem,
  },
  props: {
    isActive: {
      default: false,
      type: Boolean,
    },
    text: {
      required: true,
      type: String,
    },
    author: {
      required: true,
      type: String,
    },
    place: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      IconType,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
    };
  },
  computed: {
    iconColor() {
      return this.isActive
        ? StyleColorSchemeType.SuccessDark
        : StyleColorSchemeType.Success;
    },
    carouselItemContentClassNames() {
      return new StyleClassNamesBuilder('home-carousel-item-content')
        .addConditionClassName('active', this.isActive)
        .build();
    },
  },
};
