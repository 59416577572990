
import HomeSection from '../section';
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import Gutter from '@/ui-kit/components/gutter';
import HomeCarousel from './carousel';
import HomeFeaturedInSection from '../featured-in-section';
import HomeStoreName from '@/store/home/name';

export default {
  name: 'HomeCarouselSection',
  components: {
    HomeFeaturedInSection,
    HomeCarousel,
    Gutter,
    HomeSection,
  },
  data() {
    return {
      routes,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
    };
  },
  computed: {
    customerFeedbackContent() {
      return this.$store.state[HomeStoreName].customerFeedbackContent;
    },
  },
};
