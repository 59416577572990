export const PrismicLayoutType = {
  NewHomePage: 'new_home_page',
  NewBuyYourHomePage: 'new_buy_your_home_page',
  NewSellYourHomePage: 'new_sell_your_home_page',
  NewBuySellYourHomePage: 'new_buy_sell_your_home_page',
  ProMarketplaceLandingPage: 'pro_marketplace_landing_page',
  NewContactPage: 'new_contact_page',
  OffersManagement: 'offer_management',
  SuccessPage: 'success_page',
  BodiVerselp: 'bodiverselp',
  MarketplaceForPros: 'marketplace_for_pros',
  ProSignup: 'pro_signup',
  WhyBode: 'why_bode',
  NewMyAbodeOverview: 'new_my_abode_overview',
  SoldData: 'sold_data',
  MarketData: 'marketdatanew',
  WhoWeAre: 'who_we_are',
  RequestSticker: 'request_sticker',
  CalendarPage: 'calendar',
  MyListingsPage: 'my_listings_page',
  UserPage: 'user_page',
  MessagesPage: 'messages_page',
  ProMarketPlaceServiceRequestPage: 'pro_marketplace_service_request_page',
  ProMarketPlaceServiceRequestPhotographyPage: 'pro_marketplace_service_request_photography',
  SettingsAccountPage: 'settings_account_page',
  SettingsProfilePage: 'settings_profile_page',
  SettingsNotificationsPage: 'settings_notifications_page',
  SettingsPropertiesPage: 'settings_properties_page',
  SettingsVerificationPage: 'settings_verification_page',
  ProPortal: 'pro_portal',
};

export const PrismicTagType = {
  HomePage: 'home-page',
  OffersManagementSeller: 'offers-management-seller',
  OffersManagementBuyer: 'offers-management-buyer',
  NewContactPage: 'new-contact-page',
  ListingSubmitSuccess: 'listing-submit-success',
  OfferSubmitSuccess: 'offer-submit-success',
};

export const PrismicFragmentType = {
  DocumentType: 'document.type',
  DocumentTags: 'document.tags',
};

export const PrismicDocumentType = {
  Repeatable: 'repeatable',
  Single: 'single',
};

export const PrismicDataByTag = {
  [PrismicTagType.OffersManagementSeller]: {
    documentType: PrismicDocumentType.Repeatable,
    layoutType: PrismicLayoutType.OffersManagement,
  },
  [PrismicTagType.OffersManagementBuyer]: {
    documentType: PrismicDocumentType.Repeatable,
    layoutType: PrismicLayoutType.OffersManagement,
  },
  [PrismicTagType.HomePage]: {
    documentType: PrismicDocumentType.Single,
    layoutType: PrismicLayoutType.NewHomePage,
  },
  [PrismicTagType.NewContactPage]: {
    documentType: PrismicDocumentType.Single,
    layoutType: PrismicLayoutType.NewContactPage,
  },
  [PrismicTagType.ListingSubmitSuccess]: {
    documentType: PrismicDocumentType.Repeatable,
    layoutType: PrismicLayoutType.SuccessPage,
  },
  [PrismicTagType.OfferSubmitSuccess]: {
    documentType: PrismicDocumentType.Repeatable,
    layoutType: PrismicLayoutType.SuccessPage,
  },
};

export default {
  PrismicLayoutType,
  PrismicTagType,
  PrismicDataByTag,
};
