
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import Paper from '@/ui-kit/components/paper';
import Gutter from '@/ui-kit/components/gutter';
import Divider from '@/ui-kit/components/divider';
import { TypographyType } from '@/ui-kit/components/typography/enums';
import Typography from '@/ui-kit/components/typography';

export default {
  name: 'HomeFeaturedInInfo',
  components: {
    Divider,
    Paper,
    Gutter,
    Typography,
  },
  props: {
    amount: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      routes,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      TypographyType,
    };
  },
};
