
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import Gutter from '@/ui-kit/components/gutter';
import Button from '@/ui-kit/components/button';
import { ButtonFillType } from '@/ui-kit/components/button/enums';
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'HomeSecurityInterestingSuggestion',
  components: {
    Button,
    Gutter,
  },
  props: {
    className: {
      default: '',
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    redirectTo: {
      required: true,
      type: String,
    },
    colorScheme: {
      default: StyleColorSchemeType.Secondary,
      type: String,
    },
    fill: {
      default: ButtonFillType.Filled,
      type: String,
    },
  },
  data() {
    return {
      StylePositionType,
      StyleSizeType,
      HomePageEvent,
    };
  },
  computed: {
    interestingSuggestionBlockClassName() {
      return new StyleClassNamesBuilder('home-security-section-interesting-suggestion')
        .addClassName(this.className)
        .build();
    },
  },
};
