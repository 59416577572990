
import HomeSection from '../section';
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import HomeBodeFeature from './feature';
import { HomeBodeFeatureType } from './enums';
import Gutter from '@/ui-kit/components/gutter';
import HomeStoreName from '@/store/home/name';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'HomeBodeFeaturesSection',
  components: { Gutter, HomeBodeFeature, HomeSection },
  data() {
    return {
      routes,
      HomeBodeFeatureType,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      HomePageEvent,
    };
  },
  computed: {
    bodeFeaturesContent() {
      return this.$store.state[HomeStoreName].bodeFeaturesContent;
    },
  },
};
