
import HomeSection from '../section';
import HomeSecurityTitleDescription from './title-description';
import HomeSecurityInterestingSuggestion from './interesting-suggestion';
import { StyleColorSchemeType, StylePositionType, StyleSizeType } from '@/ui-kit/styles/enums';
import Gutter from '@/ui-kit/components/gutter';
import {
  HOME_SECURITY_INTERESTING_SUGGESTIONS_CONTENT,
} from '@/components/page-specific/home/security-section/constants';
import HomeStoreName from '@/store/home/name';
import routes from '@/constants/routes';
import { ButtonFillType } from '@/ui-kit/components/button/enums';
import { Comparator } from '@/ui-kit/enums';

export default {
  name: 'HomeSecuritySection',
  components: {
    Gutter,
    HomeSection,
    HomeSecurityTitleDescription,
    HomeSecurityInterestingSuggestion,
  },
  data() {
    return {
      HOME_SECURITY_INTERESTING_SUGGESTIONS_CONTENT,
      StylePositionType,
      StyleSizeType,
    };
  },
  computed: {
    imageSizes() {
      return [
        {
          comparator: Comparator.Less,
          breakpoint: StyleSizeType.XSmall,
          imageSize: 400,
        },
        {
          comparator: Comparator.Less,
          breakpoint: StyleSizeType.Small,
          imageSize: 600,
        },
        {
          comparator: Comparator.Less,
          breakpoint: StyleSizeType.Medium,
          imageSize: 960,
        },
        {
          comparator: Comparator.Greater,
          breakpoint: StyleSizeType.Medium,
          imageSize: 1400,
        },
      ];
    },
    interestingBlockItems() {
      return [
        {
          title: this.bodeIsSecureContent.sign_in_title,
          subtitle: this.bodeIsSecureContent.sign_in_description,
          redirectTo: routes.signup,
          buttonText: 'Sign Up',
          buttonColorScheme: StyleColorSchemeType.Secondary,
          buttonFilling: ButtonFillType.Filled,
        },
      ];
    },
    bodeIsSecureContent() {
      return this.$store.state[HomeStoreName].bodeIsSecureContent;
    },
  },
};
