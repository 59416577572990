
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import { HomeBodeFeatureType } from './enums';
import { IconType } from '@/ui-kit/components/icon/enums';
import Link from '@/ui-kit/components/link';
import Gutter from '@/ui-kit/components/gutter';
import LazyImage from '@/components/lazy-image';
import { Comparator } from '@/ui-kit/enums';

export default {
  name: 'HomeBodeFeature',
  components: { Gutter, Link, LazyImage },
  props: {
    image: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    redirectTo: {
      required: true,
      type: String,
    },
    linkText: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    trackingTitle: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      routes,
      IconType,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
    };
  },
  computed: {
    imageSizes() {
      return [
        {
          comparator: Comparator.Less,
          breakpoint: StyleSizeType.Medium,
          imageSize: 240,
        },
        {
          comparator: Comparator.Greater,
          breakpoint: StyleSizeType.Medium,
          imageSize: 240,
        },
      ];
    },
    featureBlockClassNames() {
      return new StyleClassNamesBuilder('home-bode-feature')
        .addConditionClassNames(Object.values(HomeBodeFeatureType), this.type)
        .build();
    },
  },
  methods: {
    trackLink() {
      this.$track(this.trackingTitle);
    },
  },
};
