
import HomeFeaturedInInfo from './info';
import HomeFeaturedInCarousel from './carousel';
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import Gutter from '@/ui-kit/components/gutter';
import HomeStoreName from '@/store/home/name';
import ExternalLink from '@/ui-kit/components/external-link';
import Icon from '@/ui-kit/components/icon';
import { IconType } from '@/ui-kit/components/icon/enums';
import { ExternalLinks } from '@/utils/external-navigation/constants';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'HomeFeaturedInSection',
  components: {
    Icon,
    ExternalLink,
    HomeFeaturedInInfo,
    HomeFeaturedInCarousel,
    Gutter,
  },
  data() {
    return {
      routes,
      IconType,
      ExternalLinks,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      HomePageEvent,
    };
  },
  computed: {
    featuredInContent() {
      return this.$store.state[HomeStoreName].featuredInContent;
    },
    soldPreviewContent() {
      return this.$store.state[HomeStoreName].soldPreviewContent;
    },
  },
};
