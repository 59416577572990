
import HomeBlockWithPhone from '@/components/page-specific/home/block-with-phone';
import HomeBodeFeaturesSection from '@/components/page-specific/home/bode-features-section';
import HomeCarouselSection from '@/components/page-specific/home/carousel-section';
import HomeSecuritySection from '@/components/page-specific/home/security-section';
import routes from '@/constants/routes';
import WindowScrollHelper from '@/ui-kit/helpers/WindowScrollHelper';
import { getAction, SET_CONTENT_DATA } from '@/store/home/actions';
import HomeStoreName from '@/store/home/name';
import { PrismicLayoutType } from '@/constants/prismicContent';
import headMeta from '@/constants/headMeta';
import LocalStorageService from '@/services/LocalStorageService/LocalStorageService';
import { LocalStorageItemKey } from '@/services/LocalStorageService/enums';
import { Layout } from '@/enums';

export default {
  name: 'HomePage',
  components: {
    HomeSecuritySection,
    HomeCarouselSection,
    HomeBodeFeaturesSection,
    HomeBlockWithPhone,
  },
  layout: Layout.Default,
  data() {
    return {
      meta: {
        title: null,
        description: null,
      },
      isInitialized: false,
      routes,
      hero: null,
      sections: [],
    };
  },
  // TODO: Change to asyncData When new prismic integration is implemented
  async fetch() {
    if (this.isContentLoaded) {
      return;
    }

    const { data } = await this.getContent();

    this.meta.title = data.meta_title;
    this.meta.description = data.meta_description;
    this.meta.image = data.meta_image;

    await this.$store.dispatch(getAction(SET_CONTENT_DATA), data);
  },
  head() {
    return {
      title: this.meta.title,
      meta: [
        { hid: 'description', name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
        { property: 'og:url', content: `${headMeta.domain}${routes.root}` },
        { property: 'og:site_name', content: headMeta.siteName },
        { property: 'og:type', content: headMeta.type },
        { property: 'og:image', content: this.meta.image?.url },
      ],
    };
  },
  computed: {
    isContentLoaded() {
      return this.$store.state[HomeStoreName].isContentLoaded;
    },
  },
  mounted() {
    WindowScrollHelper.scrollOnTopSmoothly();
    this.getGrowSurfId();
  },
  methods: {
    getContent() {
      return this.$prismic.client.getSingle(PrismicLayoutType.NewHomePage);
    },
    getGrowSurfId() {
      const growSurfId = this.$route.query.grsf;

      LocalStorageService.add(LocalStorageItemKey.GrowSurf, growSurfId);
    },
  },
};
