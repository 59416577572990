import routes from '@/constants/routes';
import { ButtonFillType } from '@/ui-kit/components/button/enums';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';

export const HOME_SECURITY_INTERESTING_SUGGESTIONS_CONTENT = [
  {
    title: 'Interested in buying or selling your own home?',
    subtitle: 'Get started with a free Bode account.',
    redirectTo: routes.signup,
    buttonText: 'Sign Up',
    buttonColorScheme: StyleColorSchemeType.Secondary,
    buttonFilling: ButtonFillType.Filled,
  },
  {
    title: 'Interested in joining our list of recommended professionals?',
    subtitle: 'Apply to be a part of the Pro Marketplace today.',
    redirectTo: routes.search,
    buttonText: 'Find Out Now',
    buttonColorScheme: StyleColorSchemeType.Gray,
    buttonFilling: ButtonFillType.Outlined,
  },
];

export default {
  HOME_SECURITY_INTERESTING_SUGGESTIONS_CONTENT,
};
